.fab {
    display: inline-block;
    position: fixed;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    width: auto;
    height: 48px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    z-index: 5;
    background: #00A3DD;
    color: #FFF;
    border-radius: 999999px;
    border-color: rgba(0, 0, 0, 0);
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
    bottom: 1.6rem;
    right: 1.6rem;
}

b {
    font-weight: 600;
}
