@media print, screen and (min-width: 769px) {
    .modal-content {
        width: auto;
        min-width: 70vw;
        max-width: 70vw;
    }
}

.checkbox input {
    margin-right: 1rem;
}
