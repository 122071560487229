:root {
    --servex-color-light: #33b5e4;
    --servex-color: #00A3DD;
    --servex-color-dark: #0082b1;

    --navbar-color: #fff;
    --navbar-color-dark: #eee;
}

html {
    background-color: #fafafa;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

h1 {
    font-size: 3rem;
    font-weight: 700;
}

h2 {
    font-size: 2.7rem;
    font-weight: 600;
}

h3 {
    font-size: 2.3rem;
    font-weight: 500;
}

h4 {
    font-size: 1.9rem;
}

h5 {
    font-size: 1.5rem;
}

h6, p {
    font-size: 1.1rem;
}

.button p {
    font-size: 1rem;
}

.hero.is-fullheight .hero-body,
.hero.is-halfheight .hero-body {
    align-items: stretch !important;
}

.button.is-info,
.file.is-primary .file-cta,
.file.is-info .file-cta {
    background-color: var(--servex-color);
}

.button.is-info:hover,
.button.is-info:focus,
.file.is-primary:hover .file-cta,
.file.is-primary:focus .file-cta,
.file.is-info:focus .file-cta ,
.file.is-info:focus .file-cta {
    background-color: var(--servex-color-dark);
}

@media screen and (min-width: 1088px) {
    .container {
        max-width: 95% !important;
    }
}
