:root {
    --snackbar-margin-bottom: 1.6rem;
}

.snackbar {
    --snackbar-margin: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 1px;
    width: auto;
    max-width: 50vw;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    padding: 0.8rem 1rem;
    position: fixed;
    z-index: 15;
    margin-left: var(--snackbar-margin);
    margin-right: var(--snackbar-margin);
    bottom: var(--snackbar-margin-bottom);
    -webkit-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.4);
    font-size: 1.1rem;
}


@media screen and (max-width: 630px) {
    :root {
        --snackbar-margin-bottom: 6rem;
    }

    .snackbar {
        max-width: 100vw;
    }
}

@media screen and (min-width: 1080px) {
    .snackbar {
        max-width: 30vw;
    }
}

.snackbar p {
    font-size: 1.1rem;
    text-align: left;
    margin-right: 2rem;
    max-width: 100%;
    word-break: break-word;
}

.snackbar.error,
.snackbar.is-error {
    background-color: #ff3860;
}

.snackbar.warn,
.snackbar.warning,
.snackbar.is-warn,
.snackbar.is-warning {
    background: #f7b731;
    color: #000;
}

.snackbar .mdi {
    cursor: pointer;
}

[data-text=""].snackbar {
    opacity: 0;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.snackbar.show {
    opacity: 1;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}

.snackbar.hidden {
    opacity: 0;
    -webkit-animation: fadeout 0.5s;
    animation: fadeout 0.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: var(--snackbar-margin-bottom);
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: var(--snackbar-margin-bottom);
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: var(--snackbar-margin-bottom);
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: var(--snackbar-margin-bottom);
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
