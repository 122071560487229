.modal {
    z-index: 10;
}

.modal-card {
    width: 100%;
}

@media print, screen and (max-width: 400px) {
    .modal-content {
        max-width: 100vw;
    }
}

@media print, screen and (max-width: 769px) {
    .modal-content {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .modal-card {
        margin: 0;
    }
}

.modal-background {
    background-color: rgba(10, 10, 10, .85);
}

@media print, screen and (min-width: 769px) {
    .modal-content {
        width: auto;
        min-width: 40vw;
        max-width: 70vw;
    }
}

.modal-card-foot.has-text-right {
    justify-content: flex-end !important;
}

.input:disabled,
.select:disabled,
.select select:disabled {
    color: rgba(0, 0, 0, .87);
}

.modal-card .table th {
    font-size: .8rem !important;
}

.modal-card-title {
    font-size: 1.2rem;
    font-weight: 600;
}

.modal-card-body .columns:not(:last-child) {
    margin-bottom: 0;
}

.modal-card-body .field:not(:last-child) {
    margin-bottom: 1.6rem;
}
