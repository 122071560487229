.AudioRecorder button {
    background-color: var(--servex-color);
    border-color: transparent;
    border-width: 1px;
    color: #fff;
    cursor: pointer;
    justify-content: center;
    padding: calc(.375em - 1px) .75em;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
    -webkit-appearance: none;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
}

p.is-danger {
    color: #ff3860;
}

audio, video {
    min-height: 3.6rem;
}

.field.has-addons.has-addons-centered.engine-selector {
    margin-top: .6rem;
}

.mdi.mdi-check-circle {
    color: #20bf6b;
}

.mdi.mdi-alert-octagon {
    color: #ff3860;
}

.table {
    background-color: transparent;
}

.table td,
.table tr {
    text-align: center;
    vertical-align: middle;
    font-size: 1.05rem;
}

.table.recording-results td:last-child,
.hero-body .table.recording-results.is-narrow:not(.is-parsed) td:last-child {
    border-left: 1px solid #dbdbdb;
}

.file.has-name .file-name {
    text-align: center;
}

fieldset {
    border: none;
}

fieldset[disabled].audio-upload * {
    cursor: not-allowed;
}

fieldset[disabled].audio-upload .file.is-primary .file-cta,
fieldset[disabled].audio-upload .file.is-info .file-cta {
    background-color: rgba(0, 0, 0, .2);
}
