:root {
    --spinner-color-a: #349ce2;
    --spinner-color-b: #8854d0;
    --spinner-color-c: #102f44;
    --spinner-color-d: #4caf50;
}

.spinner {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}

.spinner:not(.is-parsed) {
    vertical-align: center;
    align-items: center;
    justify-content: center;
}

.loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spinner-container .loading-spinner {
    animation: rotate 2.625s linear infinite;
    height: 48px;
    transform-origin: center;
    width: 48px;
}

.loading-spinner-container .loading-spinner .path {
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out 0.75s infinite;
    stroke: var(--spinner-color-a);
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100, 150;
        stroke-dashoffset: -24;
    }
    100% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: -124;
    }
}

@-moz-keyframes dash {
    0% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100, 150;
        stroke-dashoffset: -24;
    }
    100% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: -124;
    }
}

@-o-keyframes dash {
    0% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100, 150;
        stroke-dashoffset: -24;
    }
    100% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100, 150;
        stroke-dashoffset: -24;
    }
    100% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: -124;
    }
}

@-webkit-keyframes color {
    0%,
    100% {
        stroke: var(--spinner-color-a);
    }
    25% {
        stroke: var(--spinner-color-b);
    }
    50% {
        stroke: var(--spinner-color-c);
    }
    75% {
        stroke: var(--spinner-color-d);
    }
}

@-moz-keyframes color {
    0%,
    100% {
        stroke: var(--spinner-color-a);
    }
    25% {
        stroke: var(--spinner-color-b);
    }
    50% {
        stroke: var(--spinner-color-c);
    }
    75% {
        stroke: var(--spinner-color-d);
    }
}

@-o-keyframes color {
    0%,
    100% {
        stroke: var(--spinner-color-a);
    }
    25% {
        stroke: var(--spinner-color-b);
    }
    50% {
        stroke: var(--spinner-color-c);
    }
    75% {
        stroke: var(--spinner-color-d);
    }
}

@keyframes color {
    0%,
    100% {
        stroke: var(--spinner-color-a);
    }
    25% {
        stroke: var(--spinner-color-b);
    }
    50% {
        stroke: var(--spinner-color-c);
    }
    75% {
        stroke: var(--spinner-color-d);
    }
}
