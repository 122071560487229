.navbar {
    background-color: var(--navbar-color);
}

.navbar * {
    color: #000 !important;
}

.navbar.has-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.navbar-brand .field {
    margin-top: .5rem;
    vertical-align: center;
    justify-content: center;
    margin-bottom: 0;
}

.navbar-brand .field .control .select::after {
    /* margin-top: .4rem; */
    border-color: #000;
}

@media screen and (max-width: 1087px) {
    .navbar-menu {
        background-color: var(--navbar-color);
    }

    .navbar-brand .field .control .select {
        margin-left: .6rem;
    }

    .navbar-brand select {
        background-color: var(--navbar-color);
        color: #000;
    }
}

.navbar-link.is-active,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:hover {
    background-color: var(--navbar-color-dark);
    color: #000;
}

.navbar-link.unactivable.is-active,
.navbar-link.unactivable:hover,
a.navbar-item.unactivable.is-active,
a.navbar-item.unactivable:hover {
    background-color: var(--navbar-color) !important;
    color: #000;
}

.navbar-brand .navbar-item .mdi {
    font-size: 24px;
}
