.modal.small {
    z-index: 15 !important;
}

@media print, screen and (min-width: 769px) {
    .modal.small .modal-content {
        width: auto;
        min-width: 35vw;
        max-width: 60vw;
    }
}
