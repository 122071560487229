.table td, .table th {
    vertical-align: middle !important;
}

.table:not(.is-parsed) th {
    font-size: 1.5rem;
}

.table.is-narrow {
    min-width: 60%;
}

.table.is-narrow th, .table.is-narrow td {
    padding: .5rem 1rem !important;
    height: 48px !important;
}

.hero-body .table.is-narrow:not(.is-parsed) td:not(:last-child) {
    border-right: none;
}

.hero-body .table.is-narrow:not(.is-parsed) td:last-child {
    border-left: none;
}

.tag,
.tag:not(body) {
    font-size: 1rem;
    height: 1.65em;
    padding-left: .35rem;
    padding-right: .35rem;
    margin-right: .25rem;
}
